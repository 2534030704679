import React from 'react';
import {Jumbotron, Container, Row, Col, Button, Form} from 'react-bootstrap';
import {submitLearnRegistrationForm} from '../api/ApiUtils';


class LearnRegisterForm extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        isRegistered: false,
        error: '',
        errorMessage: '',
        formSubmitting: false,
        firstName: '',
        lastName: '',
        note: '',
        email: '',
        consent: false,
        path_selected: ''
      };
    }

    handleSubmit = async (e) => {
      e.preventDefault();
      console.log("submmiting login")
      console.log(this.state)
      await submitLearnRegistrationForm(this.state)
     
    }

    //handle input change
handleInputChange = (event) => {
  const target = event.target;
  const inputName = target.name;
  const inputValue = target.value;
  console.log(target.value)
 if(inputName=='consent') {
  this.setState({
    [inputName]: !this.state.consent
});
 }else {

   this.setState({
       [inputName]: inputValue
   });
 }

  
  }

 render() {
    return (
      <>
        <Jumbotron style={{ 
          backgroundImage: `url(https://img.freepik.com/free-vector/online-tutorials-concept_52683-37480.jpg?size=626&ext=jpg)`, 
          // backgroundSize: 'cover'
          height: '300px'
         }} 
          className="mt-5">

          <h1 className="px-3">Start Learning about Blockchain with Coinjupiter!</h1>
         <p>
           
         <br />
         <br />
         <br />
         </p>
        </Jumbotron>
        <br />

        <Form className="mt-5" onSubmit={this.handleSubmit}>
          <Row>
            <h2>Fill in the form below to reserve your space.</h2>
          </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridFname">
            <Form.Label>First Name</Form.Label>
            <Form.Control 
              type="text" 
              name="firstName"
              onChange={(e) => this.handleInputChange(e)}
              placeholder="First Name" />
          </Form.Group>
      
          <Form.Group as={Col} controlId="formGridLname">
            <Form.Label>Last Name</Form.Label>
            <Form.Control 
              type="text"
              name="lastName" 
              onChange={(e) => this.handleInputChange(e)}
              placeholder="Last Name" />
          </Form.Group>
        </Row>
      
        <Form.Group as={Col} controlId="floatingTextarea">
            <Form.Label>Email</Form.Label>
            <Form.Control 
              type="email"
              name="email"
              onChange={(e) => this.handleInputChange(e)}
              placeholder="Enter email" />
          </Form.Group>
      
        <Form.Group className="mb-3" controlId="formGridNote">
          <Form.Label>Note</Form.Label>
          <Form.Control 
            as="textarea"
            name="note" 
            onChange={(e) => this.handleInputChange(e)}
            placeholder="Type your comment or enquiry here." />
        </Form.Group>

      
          <Form.Group as={Col} className="mb-3" controlId="formGridState">
            <Form.Label>Select your path here</Form.Label>
            <select class="form-select" name="path_selected" onChange={(e) =>this.handleInputChange(e)} aria-label="Default select example">
              <option selected value="BlockChain">BlockChain</option>
              <option value="Cryptocurrency Trading">Cryptocurrency Trading</option>
              <option value="Artificial Inteligence">Artificial Inteligence</option>
            </select>
          </Form.Group>
      
              
      
        <Form.Group className="mb-3" id="formGridCheckbox">
          <Form.Check 
            type="checkbox" 
            name="consent"
            onChange={(e) => this.handleInputChange(e)}
            label="I don't mind receiving newsletters" />
        </Form.Group>
      
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
       </> 
    );
 }
  }


export default LearnRegisterForm


