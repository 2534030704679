import React, { useState, useEffect } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import {fetchSingleCryptoAssetsLocal,SearchEndpoint} from '../../api/ApiUtils';


function SingleCoin (props) {
    const asset_symbol = props.asset_symbol;
    const [cryptoAsset, setCryptoAsset] = useState([]);
    const [recentNews, setRecentNews] = useState([]);
    
    useEffect(() => {
        async function fetchMyAPI() {
          let response = await fetchSingleCryptoAssetsLocal(asset_symbol)
          const LatestNewsResponse = await SearchEndpoint("news", asset_symbol, 10, 0);
          setCryptoAsset(response.data.data.coin)          
          setRecentNews(LatestNewsResponse.data.data.news_feeds)          
          
        }
        fetchMyAPI()
    }, [])
    
    return (
        <div>
            <div className="row mt-5">
                <img
                  style={{ height: "50px", width: "50px" }} 
                  src={cryptoAsset?.meta?.logo_url} 
                  alt="logo"/>
                <h1 className="col h3">{cryptoAsset?.meta?.name} | {asset_symbol}</h1> 
                <div className="col">Current Price : ${cryptoAsset?.currentPrice?.ethusd}</div> 
            </div>

            <hr/>

            <div className="row">
                <div className="col-md-4 mt-2">
                    <a href={cryptoAsset?.meta?.website_url} target="_blank" rel="noreferrer" className="btn btn-primary">Website</a>
                </div>
                <div className="col-md-4 my-1"> 
                    <a href={cryptoAsset?.meta?.whitepaper_url} target="_blank" rel="noreferrer" className="btn btn-primary">White Paper</a>
                </div>
                <div className="col-md-4 my-1">
                    <a href={cryptoAsset?.meta?.blockexplorer_url} target="_blank" rel="noreferrer" className="btn btn-primary">Block Explorer</a>
                </div>
            </div>
            
            <hr/>
            <div className="row mx-2">
                <div className="h3"> Description: </div>
                {cryptoAsset?.meta?.description}
            </div>

            <hr/>
            <div className="row mt-2">
                <div className="col-6"> Chart
                </div>
                <div className="col-6">
                <Card style={{ width: '100%', borderRadius: "0px" }} className="mb-3 justify-content-center">
        <h2>Latest news on {cryptoAsset?.meta?.name} | {asset_symbol}</h2>
        {recentNews?.map((newsItem, i) => {

          return (

            <div className="row">

              <div className="col">

                <a href={`/newsitem/${newsItem.url_slug}`}><br />{newsItem.title} - </a>{newsItem.views_count} views
              </div>
            </div>

          )
        })}
      </Card>
      </div>
            </div>

            
        </div>
        
    );
  }


export default SingleCoin