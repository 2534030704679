import React, { useState, useEffect } from 'react';
import {fetchSingleGlossarybySlug} from '../../api/ApiUtils';
import {TwitterShareButton, TwitterIcon, RedditShareButton, RedditIcon} from 'react-share';
import HorizontalBanner from "../adsBanners/horizontal"


function ComingSoon (props) {
    const [glossary, setGlossary] = useState([]);
    // console.log(props.properties.match.params.urlslug);
    useEffect(() => {
        async function fetchMyAPI() {
          let response = await fetchSingleGlossarybySlug(props.properties.match.params.urlslug)
          setGlossary(response.data.data.glossary)
          
        }
        fetchMyAPI()
    },[])

    return (
        <div>
        <div className="card mt-5">
            <h1 className="card-header h2">{glossary?.title}</h1>
            <div className="card-body">
                <h5 className="card-title">{glossary?.news_room_name}</h5>
                {/* <p>Views: <i className="fas fa-eye me-5">{" "+glossary.views_count}</i></p> */}
                {/* <img src={glossary?.news_room_logo_url} alt="logoimg"/> */}
                <p className="card-text">{glossary?.body}</p>
               
                <div className="row mt-5">
                          <div className="col">
                            <TwitterShareButton
                              title={`${glossary.title} #CryptoEducation`}
                              via={`coinjupiter`}
                              url={`https://www.coinjupiter.com/glossary/${glossary.url_slug}`}
                              children={
                                <TwitterIcon size={60} round={true} />
                              }
                            />
                            <RedditShareButton
                              title={glossary.title}
                              url={`https://www.coinjupiter.com/glossary/${glossary.url_slug}`}
                              children={
                                <RedditIcon size={60} round={true} />
                              }
                            />
                          </div>                       
                </div>
            </div>
        </div>
        <HorizontalBanner />
        </div>
        
    );
  }


export default ComingSoon