import Container from 'react-bootstrap/Container'

import Header from '../components/header'
import Footer from '../components/footer'
import Exchanges from '../components/exchanges'


function App() {
  return (
    <div className="App">
      <Header />
      <h1 className='d-none'>Exchanges</h1>
       <Container className="pt-5">
          <Exchanges />
       </Container>
        
     <Footer />
    </div>
  );
}

export default App



