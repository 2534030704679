import React, {Component} from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class CustomPagination extends Component {
    constructor(props) {
        super(props);
          this.state = {
            pageNo: this.props.pageNo,
            pages: this.props.pages
          };
          this.handleNextPage = this.handleNextPage.bind(this);
          this.handlePreviousPage = this.handlePreviousPage.bind(this);
          this.handleFirstPage = this.handleFirstPage.bind(this);
          this.handleLastPage = this.handleLastPage.bind(this);
      }
      //move to the next page
async handleNextPage() {
    await this.setState({ pageNo: this.state.pageNo + 1 })
    let pageNo = this.state.pageNo
    if(pageNo >= this.props.pages) {
      this.setState({ pageNo: this.props.pages - 1 })
      pageNo = this.props.pages - 1;
    }
    this.props.pageHandler(pageNo)
}
//move to the previous page
async handlePreviousPage() {
    await this.setState({ pageNo: this.state.pageNo - 1 })
    let pageNo = this.state.pageNo
    if(pageNo < 1) {
        await this.setState({ pageNo: 0 })
        pageNo = 0;
    }
    this.props.pageHandler(pageNo)
}

//move to the first page
async handleFirstPage() {
  await this.setState({ pageNo: 0 })
  let pageNo = 0;
  this.props.pageHandler(pageNo)
}

//move to the last page
async handleLastPage() {
  await this.setState({ pageNo: (this.props.pages-1) })
  let pageNo = this.props.pages-1
  this.props.pageHandler(pageNo)
}
  render() {
    return (
      <>
      {this.props.pages <= 1 ? '' :
      <Pagination size="md" className="col-12 ">
        {this.state.pageNo === 0 ? '' : <PaginationItem><PaginationLink first onClick={this.handleFirstPage} /></PaginationItem>}
        {this.state.pageNo === 0 ? '' : <PaginationItem><PaginationLink previous onClick={this.handlePreviousPage} /></PaginationItem>}
        {this.state.pageNo === 0 ? '' : <PaginationItem><PaginationLink onClick={this.handlePreviousPage}>{this.state.pageNo}</PaginationLink></PaginationItem>}
        <PaginationItem active><PaginationLink>{this.state.pageNo + 1}</PaginationLink></PaginationItem>
        {this.state.pageNo === (this.props.pages - 1) ? '' : <PaginationItem><PaginationLink onClick={this.handleNextPage}>{this.state.pageNo + 2}</PaginationLink></PaginationItem>}
        {this.state.pageNo === (this.props.pages - 1) ? '' : <PaginationItem><PaginationLink next onClick={this.handleNextPage} /></PaginationItem>}
        {this.state.pageNo === (this.props.pages - 1) ? '' : <PaginationItem><PaginationLink last onClick={this.handleLastPage} /></PaginationItem>}
      </Pagination>
    }
      </>
    );
  }
}
export default CustomPagination;