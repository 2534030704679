import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import { SearchEndpoint } from '../api/ApiUtils';


class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: this.props.pageNo,
            term: this.props.term,
            limit: this.props.limit,
            model: this.props.model
        }
    }
        //handle input change
handleInputChange = (event) => {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;
    console.log(target.value)  
     this.setState({
         [inputName]: inputValue
     }); 
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        console.log("submmiting login")
        console.log(this.state)
        const {pageNo, limit, model, term} = this.state
        this.props.searchHandler(pageNo, limit, model, term)
       
      }

    render() {
        return (
            <>
                <Form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <Form.Control
                            type="text"
                            name="term"
                                id="inlineFormInputName"
                                placeholder="Enter search term..."
                                onChange={(e) => this.handleInputChange(e)}
                            />
                        </div>
                        <div className="col">
                            <Button type="submit" >Search</Button>
                        </div>
                    </div>
                </Form>
            </>
        );
    }
}
export default Search;