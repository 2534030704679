import Container from 'react-bootstrap/Container'

import Header from '../components/header'
import Footer from '../components/footer'
// import Sidebar from "../components/Sidebar"
import HorizontalBanner from "../components/adsBanners/horizontal"
import GlossaryComponent from '../components/glossary/glossary'



function App() {
  return (
    <div className="App">
      <Header />
      <h1 className='d-none'>Glossary</h1>
       <Container className="pt-5">
          <HorizontalBanner />
          <GlossaryComponent />
       </Container>
        
     <Footer />
    </div>
  );
}

export default App;



