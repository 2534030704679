import Container from 'react-bootstrap/Container'

import Header from '../components/header'
import Footer from '../components/footer'
import SingleCoinPage from '../components/singleCoinPage'


function App() {
  // console.log(window.location.pathname);
  const asset_path_name = window.location.pathname;
  const asset_symbol = asset_path_name.split("/")[2]
  // console.log(asset_symbol);

  return (
    <div className="App">
      <Header />

       <Container className="pt-5">
          <SingleCoinPage asset_symbol={asset_symbol} />
       </Container>
        
     <Footer />
    </div>
  );
}

export default App



