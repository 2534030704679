import Container from 'react-bootstrap/Container'

import Header from '../components/header'
import Footer from '../components/footer'
import SingleGlossaryItem from '../components/glossary/singleGlossary'


function App(props) {
    const properties = props;
  return (
    <div className="App">
      <Header />

       <Container className="pt-5">
          <SingleGlossaryItem properties={properties}/>
       </Container>
        
     <Footer />
    </div>
  );
}

export default App



