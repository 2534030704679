import React, { useState, useEffect } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import Moment from 'react-moment';
import 'moment-timezone';
import { latestGlossary, SearchEndpoint } from '../../api/ApiUtils';
import CustomPagination from '../pagination';
import Search from '../search';
import { TwitterShareButton, TwitterIcon, RedditShareButton, RedditIcon } from 'react-share';
import ScrollButton from '../htmlElements/scrollButton';
import HorizontalBanner from "../../components/adsBanners/horizontal"


function SidebarGlossary(props) {
  const [glossary, setGlossary] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [recordsCount, setRecordsCount] = useState(0)
  const [limit, setLimit] = useState(10)
  const [Pages, setPages] = useState(0)

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await latestGlossary(pageNo, limit)
      // console.log(response.data.data.news_feeds.news_feeds);
      setGlossary(response.data.data.glossary.glossary)
      setRecordsCount(response.data.data.glossary.total_glossary)
      setPages(response.data.data.glossary.pages)
    }
    fetchMyAPI()
  }, [])

  async function pageHandler(pageNo) {
    setPageNo(pageNo)
    const response = await latestGlossary(pageNo, limit);
    setGlossary(response.data.data.glossary.glossary)
    setRecordsCount(response.data.data.glossary.total_glossary)
    setPages(response.data.data.glossary.pages)
  }

  async function searchHandler(pageNo, limit, model, term) {
    setPageNo(pageNo)
    const response = await SearchEndpoint(model, term, limit, pageNo);
    setGlossary(response.data.data.glossary)
    setRecordsCount(response.data.data.total_glossary)
    setPages(response.data.data.pages)
  }
  return (
    <div className="mt-5" style={{border: "2px"}}>
    <Card style={{ width: '100%', borderRadius: "0px" }} className="mb-3 justify-content-center">
      <h2>Blockchain Glossary</h2>
      {glossary?.map((glossaryItem, i) => {
        
        return (

          <Card style={{ width: '100%', borderRadius: "30px" }} className="mb-3 justify-content-center">
            <Card.Body>
              <div className="row">
                {/* <div className="col-2 d-none d-lg-block">
                  <img
                    style={{ height: "150px", width: "150px" }}
                    src={glossaryItem.news_room_logo_url} />
                </div> */}
                <div className="col">
                  {/* <Card.Title>{glossaryItem.news_room_name}</Card.Title> */}
                  <Card.Subtitle className="mb-2 text-muted">
                        <Card.Link href={`/glossary/${glossaryItem.url_slug}`}><br />{glossaryItem.title}</Card.Link>
                    </Card.Subtitle>
                  <Card.Text>
                    {glossaryItem.metadata}
                  </Card.Text>
                 
                  {/* <strong><Moment fromNow>{glossaryItem.createdAt}</Moment></strong>
                  <Card.Link href={`/glossary/${glossaryItem.url_slug}`}><br />View!!</Card.Link> */}

                </div>
              </div>
            </Card.Body>
            {/* {(i !== 0 && (i%5) === 0) ? <HorizontalBanner/> : ""} */}
            
          </Card>

        )
      })}
      </Card>
    </div>

  );
}


export default SidebarGlossary