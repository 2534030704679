import React from 'react';
import GLOBAL_STRINGS from '../../assets/strings';
import ComingSoonImg from '../../assets/img/comingsoon.jpg'

function Calculator (props) {
    return (
        
        <div>
            <img style={{ height: "100%", width: "100%" }} src={ComingSoonImg} alt="coming soon image" />
        </div>
        
    );
  }


export default Calculator