import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card'
import {exchangesInfos} from '../api/ApiUtils';
import ScrollButton from './htmlElements/scrollButton';
import Sidebar from './Sidebar';


function News (props) {
  const [exchangeInfos, setExchangesInfos] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [recordsCount, setRecordsCount] = useState(0)
  const [limit, setLimit] = useState(10)

  useEffect(() => {
      async function fetchMyAPI() {
        let response = await exchangesInfos(pageNo, limit)
        let exchanges = Object.values(response.data.Data)
        exchanges.sort((a, b) => (a.TOTALVOLUME24H.USD < b.TOTALVOLUME24H.USD) ? 1 : -1)
        console.log(exchanges);
        setExchangesInfos(Object.values(exchanges))
        setRecordsCount(Object.values(response.data.Data).length)
      }
      fetchMyAPI()
  },[])
    
    return (
        <div className="row mt-5">
          <div className="col-12 col-lg-8 card mt-5">
            {exchangeInfos.map((exchangeItem, i) => {
                
                return (
                   
                            <Card style={{ width: '100%' }} className="mb-3 justify-content-center">
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-2 d-none d-lg-block">
                                    <img
                  style={{ height: "150px", width: "150px" }} 
                  src={`https://www.cryptocompare.com${exchangeItem.LogoUrl}`} />
                                    </div>
                                    <div className="col">
                                    <Card.Title><span className="h2">{exchangeItem.Name}</span></Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{exchangeItem.Country}</Card.Subtitle>
                                    <Card.Text>
                                        {exchangeItem.Description}
                                    </Card.Text>
                                    <Card.Text>
                                      <span className="h5">Volume past 24H: </span>
                                       
                                      <i className="fab fa-bitcoin"></i> {exchangeItem.DISPLAYTOTALVOLUME24H.BTC}
                                      <br/>
                                        <span className="h5">Rating:</span> {exchangeItem.Rating.Avg}
                                    </Card.Text>
                                    <Card.Link href={exchangeItem.AffiliateURL}><br/>Visit the Exchange!</Card.Link>
                                    </div>
                                    </div>
                                </Card.Body>
                            </Card>
             ) 
            })}       
            <ScrollButton />
            </div>
            <div className="col-sm-block col-lg-4" style={{background: "white" }}>
          <Sidebar/>
        </div>
      </div>

    );
  }


export default News