import Container from 'react-bootstrap/Container'

import Header from '../components/header'
import Footer from '../components/footer'
import LearRegistrerForm from '../components/learnRegisterForm'


function App() {
  return (
    <div className="App">
      <Header />

       <Container className="pt-5">
            <LearRegistrerForm />
       </Container>
        
     <Footer />
    </div>
  );
}

export default App

